@import '~antd/dist/antd.css';

#root,
.App,
.layout {
  background-color: #fff;
}

.main-title {
  text-align: center;
  margin: 20px;
}

.sub-title {
  text-align: center;
  margin: 20px;
}

.items > div {
  padding: 12px;
  /* margin-right: 24px;
  margin-bottom: 24px; */
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.highest-bids .ant-card-meta-description {
  color: #000;
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.link-to-information {
  text-align: center;
  margin-bottom: 36px;
}

.link-to-information > a {
  border: #ff8080 1px solid;
  padding: 1rem;
  text-decoration: underline;
  color: #ff8080;
}

.link-to-mybids {
  text-align: center;
  margin-top: 26px;
  margin-bottom: 18px;
}

.link-to-mybids > a {
  /* border: #80aa80 1px solid; */
  padding: 1rem;
  text-decoration: underline;
  color: #80aa80;
}

.categories {
  text-align: center;
  margin-top: 3rem;
}
.categories a {
  text-decoration: underline;
}
.categories > li {
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
  border-right: 1px solid #ccc;
}
.categories > li:first-child {
  padding-left: 0;
}
.categories > li:last-child {
  padding-right: 0;
  border-right: none;
}

@media screen and (max-width: 575px){ /* xs */
}

@media screen and (min-width: 576px){ /* sm */
}

@media screen and (min-width: 768px){ /* md */
}

@media screen and (min-width: 992px){ /* lg */
}

@media screen and (min-width: 1200px){ /* xl */
}

/* amplify-authenticator {
  background: tomato;
  padding: 5px;
} */

div.price {
  margin-bottom: 1rem;
}

div.price > span {
  font-size: 2rem;
  margin: 0.5rem;
}

div.price > div.highest {
  display: inline-block;
}

div.highest > span {
  font-size: 1.5rem;
}

.bid {
  margin-left: 0.5rem;
}

.highest > span {
  font-size: 2rem;
  margin: 0.5rem;
}

.notes-divider {
  margin-top: 2rem !important;
}

.mt-16 {
  margin-top: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.text-center {
  text-align: center;
}

.login .login-form {
  max-width: 400px;
  margin: auto;
}
.login .login-form-forgot {
  float: right;
}
.login .ant-col-rtl .login-form-forgot {
  float: left;
}
.login .login-form-button {
  width: 100%;
}

.back-to {
  margin-top: 24px;
  text-align: center;
  text-decoration: underline;
}
.back-to button {
  padding: 6px 24px;
  height: initial;
}

.ant-carousel h3 {
  font-size: 5vw;
  padding-top: 5vw;
  -moz-text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
  -webkit-text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
  -ms-text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
  /* text-shadow: 1px 1px 1px #808080; */
}

@media screen and (max-width: 480px) {
  .App {
    text-align: center;
  }
  .main-title {
    font-size: 2rem!important;
  }
  .ant-carousel h3 {
    font-size: 7vw;
  }
  .product-name {
    margin-top: 1rem;
  }
  .link-to-information {
    margin-bottom: 1rem;
  }
  .link-to-information > a {
    padding: 0.7rem;
  }
  .categories {
    padding-left: 0;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .readme {
    font-size: 1.5rem!important;
    margin-bottom: 2rem!important;
  }
  .notes {
    text-align: left;
  }
  .attention li.ant-list-item {
    text-align: left;
  }
}

:root {
  /* --amplify-primary-color: #ff6347;
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #e0573e; */
  --amplify-text-sm: 1rem;
  --amplify-text-xs: 1rem;
  --amplify-text-xxs: 1rem;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-select-selector,
.ant-select-item-option-content {
  font-size: 1rem;
}
